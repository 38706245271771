/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Map = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        <h2 className="text-center">
          We Cover {rpdata?.dbPrincipal?.miles} Miles Around:
        </h2>
        <div className="w-4/5 mx-auto pb-10">
          <Tabs>
            <TabList>
              {rpdata?.dbPrincipal?.location?.slice(1, 4).map((item, index) => {
                return <Tab key={index}>{item.address}</Tab>;
              })}
            </TabList>

            {rpdata?.dbPrincipal?.location?.slice(1, 4).map((item, index) => {
              return (
                <TabPanel key={index}>
                  <iframe src={item.url} className="w-full h-[500px]" />
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Map;
